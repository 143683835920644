<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">NEW AUTO LOAN</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="multi-col-validation mt-6">
        <v-layout row wrap class="align-center mx-2 ">
          <v-flex xs12 md3>
            <v-select
              v-model="selection"
              class="mx-2"
              dense
              outlined
              label="Selection"
              :items="['Employee','Non-Employee']"
              @change="selected"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3 v-if="selection==='Employee'">
            <v-select
              v-model="category_id"
              class="mx-2"
              dense
              outlined
              label="Category"
              :items="category_items"
              item-text="category"
              item-value="id"
              @change="selected_category"
            ></v-select>
          </v-flex>
          <v-flex xs12 md6 v-if="selection==='Employee'">
            <v-autocomplete
              dense
              outlined
              v-model="search"
              :items="search_items"
              :loading="isLoading"
              @keyup.enter="searching($event.target.value)"
              hide-no-data
              hide-selected
              item-text="name"
              item-value="id"
              label="Search"
              placeholder="Press Enter to Search "
              :prepend-icon="icons.mdiAccountSearchOutline"
              @change="get_search_items_info"
            ></v-autocomplete>
          </v-flex>
        </v-layout>

        <v-toolbar dense dark color="primary" class="mt-5 mb-5">
          <v-toolbar-title><h4 class="font-weight-light">BORROWER'S INFORMATION</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-layout row wrap class="align-center mx-2" v-if="selection==='Employee'">
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="name"
              label="Name"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="branch"
              label="Branch"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="align-center mx-2" v-else>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="name"
              label="Name"
              dense
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="branch"
              label="Branch"
              dense
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="align-center mx-2">
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="date_of_birth"
              label="Date of Birth"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="date"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md9>
            <v-text-field
              class="mx-2"
              v-model="place_of_birth"
              label="Place of Birth"
              dense
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="tin_no"
              label="TIN #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="sss_no"
              label="SSS #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="mobile_no"
              label="Mobile #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="telephone_no"
              label="Telephone #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="personal_email_address"
              label="Personal Email Address"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="branch_email_address"
              label="Branch Email Address"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="present_address"
              label="Present Address"
              dense
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="permanent_address"
              label="Permanent Address"
              dense
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-toolbar dense dark color="primary" class="mt-5 mb-5">
          <v-toolbar-title><h4 class="font-weight-light">EMPLOYMENT/BUSINESS INFORMATION</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-layout row wrap class="align-center mx-2">
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="name_of_employer"
              label="Name of Employer"
              dense
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="employer_address"
              label="Employer Address"
              dense
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="employment_date"
              label="Employment Date"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="date"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="employment_length"
              label="Employment Length"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="position"
              label="Position/Designation"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="monthly_gross_income"
              label="Monthly Gross Salary/Allowance"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="source_of_income"
              label="Name of Buss./Source of Income"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="monthly_busi_gross_income"
              label="Monthly Bus. Gross Income"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-toolbar dense dark color="primary" class="mt-5 mb-5">
          <v-toolbar-title><h4 class="font-weight-light">AUTO LOAN INFORMATION</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-layout row wrap class="align-center mx-2">
          <v-flex xs12 md6>
            <v-select
              v-model="vehicle_classification"
              class="mx-2"
              dense
              outlined
              label="Vehicle Classification"
              :items="['Brand New','Second Hand']"
            ></v-select>
          </v-flex>
          <v-flex xs12 md6>
            <v-select
              v-model="car_code"
              class="mx-2"
              dense
              outlined
              label="Vehicle Specification"
              :items="car_available_items"
              item-text="car_code"
              item-value="car_code"
              @change="selected_car"
            ></v-select>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="brand"
              label="Brand/Car Co."
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="chasis_no"
              label="Chasis #"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="unit"
              label="Unit"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="engine_no"
              label="Engine #"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="year_model"
              label="Year Model"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="unit_color"
              label="Unit Color"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="plate_no"
              label="Plate #"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="mv_file_no"
              label="MV File #"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>


        </v-layout>
        <v-toolbar dense dark color="primary" class="mt-5 mb-5">
          <v-toolbar-title><h4 class="font-weight-light">DATA</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-layout row wrap class="align-center mx-2">
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="loan_amount"
              label="Loan Amount"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
              min="0"
              @change="calculation"
              @keyup="calculation"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="terms"
              label="Terms (months)"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
              min="0"
              @change="calculation"
              @keyup="calculation"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="monthly_due"
              label="Monthly Ammortization"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-checkbox v-model="is_disable" label="Disable in 2 years payment" hide-details
                      class="me-3 mt-1" @change="calculation"></v-checkbox>
          <v-flex xs12 md3 v-if="!is_disable">
            <v-select
              v-model="mode"
              class="mx-2"
              dense
              outlined
              label="Mode"
              :items="['50/50 agreement','70/30 agreement']"
              @change="calculation"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3 v-if="!is_disable">
            <v-text-field
              v-if="mode==='50/50 agreement'"
              class="mx-2"
              v-model="borrower_share"
              label="Borrower Share (50%)"
              dense
              outlined
              readonly
            ></v-text-field>
            <v-text-field
              v-else
              class="mx-2"
              v-model="borrower_share"
              label="Borrower Share (30%)"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3 v-if="!is_disable">
            <v-text-field
              v-if="mode==='50/50 agreement'"
              class="mx-2"
              v-model="company_share"
              label="Company Share (50%)"
              dense
              outlined
              readonly
            ></v-text-field>
            <v-text-field
              v-else
              class="mx-2"
              v-model="company_share"
              label="Company Share (70%)"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="total_borrower_loan"
              label="Total Borrower Loan"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
        </v-layout>
        <!-- alert -->
        <v-col cols="12" v-show="alert">
          <v-alert
            color="warning"
            text
            class="mb-0"

          >
            <div class="d-flex align-start">
              <v-icon color="warning">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{alert_message}}
                </p>
              </div>
            </div>
          </v-alert>
        </v-col>

        <v-col cols="12">
          <v-btn
            color="primary"
            class="me-3 mt-4"
            @click="save_employee"
            v-if="!saving"
          >
            Save changes
          </v-btn>
          <v-progress-circular
            :size=50
            :width="5"
            color="primary"
            indeterminate
            v-else
          ></v-progress-circular>
        </v-col>
      </v-form>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      alert_message: '',

      selection: '',
      category_id: '',

      search: '',
      search_items: [],
      isLoading: false,

      name: '',
      branch: '',

      mode: '',
      is_disable: true,
      mv_file_no: '',
      plate_no: '',
      unit_color: '',
      year_model: '',
      engine_no: '',
      unit: '',
      chasis_no: '',
      brand: '',
      car_available_items: [],
      monthly_busi_gross_income: '',
      source_of_income: '',
      monthly_gross_income: '',
      position: '',
      employment_length: '',
      employment_date: '',
      employer_address: '',
      name_of_employer: '',
      permanent_address: '',
      present_address: '',
      branch_email_address: '',
      personal_email_address: '',
      telephone_no: '',
      mobile_no: '',
      sss_no: '',
      tin_no: '',
      place_of_birth: '',
      date_of_birth: '',
      vehicle_classification: '',
      car_code: '',

      terms: 0,
      monthly_due: 0,
      loan_amount: 0,
      borrower_share: 0,
      company_share: 0,
      total_borrower_loan: 0,

      inventory_id: 0,
      employee_id: 0,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('request_particulars', ['initialize_new_particulars',]),
      ...mapActions('employee', ['search_active_employee',]),
      ...mapActions('auto_loan', ['register_auto_loan']),
      ...mapActions('inventory', ['list_of_car_no_owner']),
      reset() {
        Object.assign(this.$data, initialState())
        this.initialize_data()
      },
      initialize_data() {
        this.initialize_new_particulars()
          .then(response => {
            this.category_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
        this.list_of_car_no_owner()
          .then(response => {
            this.car_available_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_car(value) {
        var index = this.car_available_items.map(function (x) {
          return x.car_code;
        }).indexOf(value)
        if (index != -1) {
          this.inventory_id = this.car_available_items[index].id
          this.brand = this.car_available_items[index].auto_dealer
          this.chasis_no = this.car_available_items[index].auto_chasis_no
          this.unit = this.car_available_items[index].auto_unit
          this.engine_no = this.car_available_items[index].auto_engine_no
          this.year_model = this.car_available_items[index].year_model
          this.unit_color = this.car_available_items[index].auto_unit_color
          this.plate_no = this.car_available_items[index].auto_plate_no
          this.mv_file_no = this.car_available_items[index].auto_mv_file_no
        }
      },
      selected() {
        this.employee_id = 0
      },
      selected_category() {
        this.name = ''
        this.branch = ''
        this.search_items = []
        this.search = ''
        this.employee_id = 0
      },
      searching(value) {
        this.name = ''
        this.branch = ''
        this.employee_id = 0

        this.isLoading = true
        this.search_active_employee({
          search_word: value,
          category_id: this.category_id,
        })
          .then(response => {
            this.search_items = response.data
            this.isLoading = false
          })
      },
      get_search_items_info(value) {
        if (this.search_items.length > 0) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(value)
          if (index != -1) {
            this.employee_id = this.search_items[index].id
            this.name = this.search_items[index].name
            this.branch = this.search_items[index].details.branch.branch_code
          } else {
            this.name = ''
            this.branch = ''
            this.employee_id = 0
          }
        } else {
          this.name = ''
          this.branch = ''
          this.employee_id = 0
        }
      },
      calculation() {
        this.total_borrower_loan = parseFloat(this.loan_amount)
        this.monthly_due = parseFloat(this.loan_amount) / parseFloat(this.terms)
        if (!this.is_disable) {
          this.borrower_share = 0
          this.company_share = 0
          var discount = 0

          if (this.mode === '50/50 agreement') {
            discount = (parseFloat(this.monthly_due) * 0.50) * (parseFloat(this.terms) >= 24 ? 24 : parseFloat(this.terms))
            this.borrower_share = parseFloat(this.loan_amount) - discount
            this.company_share = parseFloat(this.loan_amount) - discount
          } else if (this.mode === '70/30 agreement') {
            discount = (parseFloat(this.monthly_due) * 0.70) * (parseFloat(this.terms) >= 24 ? 24 : parseFloat(this.terms))
            this.borrower_share = parseFloat(this.loan_amount) - discount
            this.company_share = parseFloat(this.loan_amount) - this.borrower_share
          }
          this.total_borrower_loan = this.total_borrower_loan - discount
        }
      },
      save_employee() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('inventory_id', this.inventory_id);
          data.append('employee_id', this.employee_id);
          data.append('car_code', this.car_code);
          data.append('name', this.name);
          data.append('date_of_birth', this.date_of_birth);
          data.append('place_of_birth', this.place_of_birth);
          data.append('tin_no', this.tin_no);
          data.append('sss_no', this.sss_no);
          data.append('mobile_no', this.mobile_no);
          data.append('telephone_no', this.telephone_no);
          data.append('personal_email_address', this.personal_email_address);
          data.append('branch_email_address', this.branch_email_address);
          data.append('present_address', this.present_address);
          data.append('permanent_address', this.permanent_address);
          data.append('brand_car', this.brand);
          data.append('unit', this.unit);
          data.append('year_model', this.year_model);
          data.append('plate_no', this.plate_no);
          data.append('vehicle_classification', this.vehicle_classification);
          data.append('specify', this.specify);
          data.append('name_of_employer', this.name_of_employer);
          data.append('employer_address', this.employer_address);
          data.append('employment_date', this.employment_date);
          data.append('length_of_employment', this.employment_length);
          data.append('position_designation', this.position);
          data.append('source_of_income', this.source_of_income);
          data.append('gross_salary', this.monthly_gross_income);
          data.append('gross_income', this.monthly_busi_gross_income);
          data.append('cash_price', this.loan_amount);
          data.append('total_interest', 0);
          data.append('total_loan', this.total_borrower_loan);
          data.append('loan_terms', this.terms);
          data.append('monthly_ammortization', this.monthly_due);
          data.append('borrower_seventy', this.mode === '70/30 agreement' ? this.borrower_share : 0);
          data.append('company_thirty', this.mode === '70/30 agreement' ? this.company_share : 0);
          data.append('borrower_fifty', this.mode === '50/50 agreement' ? this.borrower_share : 0);
          data.append('company_fifty', this.mode === '50/50 agreement' ? this.company_share : 0);
          data.append('total_borrower_loan', this.total_borrower_loan);
          data.append('month_of_id', this.month_of_id);
          data.append('chasis_no', this.chasis_no);
          data.append('engine_no', this.engine_no);
          data.append('mv_file', this.mv_file_no);
          this.register_auto_loan(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving = false
              this.reset()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      }
    },
  }
</script>
